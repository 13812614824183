import React, { useEffect, useState } from "react";
import "./CPTable.scss";
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Link,
  Skeleton,
  TableSortLabel,
} from "@mui/material";

import { ReactComponent as PdfFile } from "assets/images/svg/pdf.svg";
import { ReactComponent as DocFile } from "assets/images/svg/doc.svg";
import { ReactComponent as XlsxFile } from "assets/images/svg/xlsx.svg";
import { ReactComponent as LoadingIcon } from "assets/icons/loading.svg";
import { ReactComponent as SortIcon } from "assets/icons/Reoder Ordinate.svg";
import { ReactComponent as DownloadAllIcon } from "assets/icons/arrow-right-circle.svg";

import { useNavigate } from "react-router-dom";
import CPInfoBox from "../cp-infobox/CPInfoBox";
import DownloadServices from "services/DownloadFile";
import { translate } from "services/translate";
import CPTooltip from "../cp-tooltip/CPTooltip";
import CPToast from "../cp-toast/CPToast";
import GlobalProfileServices from "services/profile";

function CPTable(props: any) {
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(props?.defaultoptions?.page || 0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    props?.defaultoptions?.size || 10
  );
  const [totalItems, setTotalItems] = React.useState(0);
  const [downloading, setDaownloding] = React.useState<any>([]);

  const [order, setOrder] = React.useState<any>("lastModified");
  const [orderBy, setOrderBy] = React.useState<any>(false);
  const [toast, setToast] = React.useState({
    open: false,
    type: "success",
    message: "success",
  });

  //   const page = useMemo(() => props.options?.currentPage, [props.options]);

  //   const totalItems = useMemo(() => props.options.totalItems, [props.options]);

  const [columns, setColumns] = React.useState([
    {
      id: "code",
      label: "ISO\u00a0Code",
      minWidth: 100,
      align: "left",
      format: (value: number) => value.toLocaleString("en-US"),
    },
  ]);
  const [rows, setRows] = React.useState([
    [
      {
        type: "string",
        value: "India",
      },
      { type: "string", value: "IN" },
    ],
  ]);

  const handleChangePage = (event: unknown, newPage: number) => {
    let timeout: any;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setPage(newPage);
      if (props.getQueryParams) {
        props.getQueryParams({ ...props?.defaultoptions, page: newPage });
      }
    }, 300);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (props.getQueryParams) {
      props.getQueryParams({
        ...props?.defaultoptions,
        size: +event.target.value,
      });
    }
  };

  //   const renderTableCell = (value: any) => {
  //     if (typeof value === "string" || typeof value === "number") {
  //       return value;
  //     } else {
  //       return "object";
  //     }
  //   };

  useEffect(
    function () {
      setColumns(props.columns);
      //   console.log("CPTable Columns ", props.columns);
      setRows(props.rows);
    },
    [props?.columns, props?.rows]
  );

  useEffect(
    function () {
      if (props.options) {
        setTotalItems(props.options.totalItems);
        setRowsPerPage(+props.options.pageSize);
        setPage(+props.options.currentPage);
      }
    },
    [props?.options]
  );

  const sortHandler = (column: any) => (event: React.MouseEvent<unknown>) => {
    // console.log(
    //   "sort Handler",
    //   event,
    //   column.property?.id || column.testData?.id || column.defaultName,
    //   column.type?.split("."),
    //   column,
    //   column.type?.split("."),
    //   column.type?.split(".").length
    // );
    // TODO: ADD sortFieldTarget
    let params: any = {
      sortField:
        column.property?.id || column.testData?.id || column.defaultName || "",
      sortFieldType:
        column.type?.split(".")[column.type?.split(".").length - 1] ||
        column.type,
      sortFieldTarget: "",
    };
    // console.log("SORT PARAM ", params);

    switch (column.tableEnum) {
      case "SAMPLE":
        params.sortFieldTarget = "SAMPLE";
        if (column.objectPath === "isTest") {
          params.sortFieldTarget = "SAMPLETEST";
          params.sortFieldType = column.testData?.type;
        }
        break;

      case "ORDER":
        params.sortFieldTarget = "ORDER";

        break;
      case "SAMPLETEST":
        params.sortFieldTarget = "SAMPLETEST";

        if (column.objectPath === "sample") {
          params.sortFieldTarget = "SAMPLE";
        } else if (
          column.objectPath === "numericResult" ||
          column.objectPath === "textResult" ||
          column.objectPath === "unit"
        ) {
          // due to an error in api, simply clear the sortFieldTarget
          params.sortFieldTarget = "";
        } else if (column.objectPath.length) {
          //if objectPath is an array, it means its test,order or sample.

          if (column.objectPath[0] === "test") {
            //if test or sample, means should look in Sample table for sort
            params.sortFieldTarget = "SAMPLE";
          } else if (column.objectPath[0] === "sample") {
            params.sortFieldTarget = "SAMPLE";
            if (column.objectPath[1] === "order") {
              params.sortFieldTarget = "ORDER";
            }
          }
        }
        break;
    }
    if (orderBy !== column.id) {
      setOrderBy(column.id);
      setOrder("asc");
      params.sortDirection = "ASC";
    } else {
      if (order === "asc") {
        setOrderBy(column.id);
        setOrder("desc");
        params.sortDirection = "DESC";
      } else if (order === "lastModified") {
        setOrderBy(column.id);
        setOrder("asc");
        params.sortDirection = "ASC";
      } else if (order === "desc") {
        setOrderBy(false);
        setOrder("lastModified");
        params.sortDirection = "";
        // params.sortField = "";
        // params.sortFieldType = "";
      }
    }
    if (props.getSortData) {
      //   console.log("SORT PARAM ", params);

      props.getSortData(params);
      setOrder((current: any) => {
        return current;
      });
    }
  };

  const downloadAll = (
    e: React.MouseEvent<HTMLElement>,
    files: any[],
    rowIndex: number
  ) => {
    e.stopPropagation();
    e.preventDefault();
    let isDownloading = downloading[rowIndex].some(
      (item: any) => item === true
    );
    if (isDownloading) return;

    // an array of filenames that had errors
    let errorList: String[] = [];
    setRows([...rows]);
    setToast({
      ...{
        open: true,
        type: "success",
        message: translate("isDownloadingAll"),
      },
    });
    let promiseDownloadAll = files.map((file: any, cellValueIndex: any) => {
      downloading[rowIndex][cellValueIndex] = true;

      return new Promise((resolve, reject) => {
        DownloadServices.getDocument({
          name: file.name,
          id: file.id || null,
          sampleId: file.sampleId || null,
          orderId: file.orderId || null,
        })
          .then(() => {
            // console.log("Called");
            downloading[rowIndex][cellValueIndex] = false;
            setDaownloding([...downloading]);

            resolve(true);
          })
          .catch((err: any) => {
            downloading[rowIndex][cellValueIndex] = false;
            setDaownloding(downloading);
            setDaownloding([...downloading]);

            errorList.push(file.name);
            reject(err);
          });
      });
    });
    Promise.all(promiseDownloadAll)
      .then((res) => {
        console.log("RES", res);
      })
      .catch((err) => {
        setToast({
          ...{
            open: true,
            type: "error",
            message: translate("failed") + " " + errorList.join(","),
          },
        });
      });
  };
  return (
    <div className="cp-data-table">
      <TableContainer>
        <Table>
          <TableHead className="cp-data-table-head">
            <TableRow className="cp-data-table-head-row">
              {columns.map((column: any, index: any) => {
                return (
                  <TableCell
                    key={index + (Math.random() * 1000).toFixed()}
                    align={column.align ? column.align : "left"}
                    style={{ minWidth: column.minWidth }}
                  >
                    <div className="cp-data-table-head-row-flex">
                      {column.label || ""}
                      {props.showSort && column.sort === true && (
                        <>
                          {/* {props.isLoading && orderBy === column.id && (
                            <TableSortLabel
                              active={true}
                              direction={orderBy === column.id ? order : "asc"}
                            ></TableSortLabel>
                          )}
                          {props.isLoading && orderBy !== column.id && (
                            <SortIcon className="cp-data-table-sort-icon"></SortIcon>
                          )} */}
                          {orderBy === column.id && (
                            <TableSortLabel
                              active={true}
                              direction={orderBy === column.id ? order : "asc"}
                              onClick={sortHandler(column)}
                            ></TableSortLabel>
                          )}
                          {orderBy !== column.id && (
                            <SortIcon
                              className="cp-data-table-sort-icon"
                              onClick={sortHandler(column)}
                            ></SortIcon>
                          )}
                        </>
                      )}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {/* Show Skeleton while Loading Data */}
          {props.isLoading === true && (
            <TableBody>
              {[1, 2, 3, 4, 5].map((index: any) => {
                return (
                  <TableRow
                    className="cp-data-table-content-row cp-data-table-content-row-loading"
                    key={"header-row-" + index}
                  >
                    {columns.map((column: any, index: any) => (
                      <TableCell
                        className="cp-data-table-content-cell"
                        align={column.align ? column.align : "left"}
                        key={"header-cell-" + index}
                        style={{ top: 57, minWidth: column.minWidth }}
                      >
                        <Skeleton height={30} variant="text" />
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
          {props.isLoading === false && rows.length > 0 && (
            <TableBody>
              {rows
                //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: any) => {
                  if (!downloading[index]) downloading[index] = [];
                  return (
                    <TableRow
                      className="cp-data-table-content-row"
                      hover
                      role="row"
                      tabIndex={-1}
                      key={
                        row.id || (Math.random() + 1).toString(36).substring(7)
                      }
                      sx={{
                        cursor:
                          row[row.length - 1]?.type === "link" ? "pointer" : "",
                      }}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        if (e.ctrlKey !== true) {
                          e.preventDefault();
                          if (
                            !GlobalProfileServices.hasSupportAccountRole() &&
                            row[row.length - 1]?.type === "link"
                          ) {
                            navigate(row[row.length - 1].value, {
                              // replace: true,
                              state: row[row.length - 1].data || "",
                            });
                          }
                        }
                      }}
                    >
                      {row.map((cell: any, cellIndex: any) => {
                        switch (cell.type) {
                          case "string":
                            if (
                              typeof cell.value === "string" ||
                              typeof cell.value === "number"
                            ) {
                              return (
                                <TableCell
                                  className={"cp-data-table-content-cell"}
                                  align={"left"}
                                  key={cell.value + cellIndex}
                                >
                                  {!GlobalProfileServices.hasSupportAccountRole() &&
                                    row[row.length - 1].type === "link" && (
                                      <a
                                        href={row[row.length - 1].value}
                                        title={cell.value || ""}
                                        aria-label={cell.value || ""}
                                      >
                                        {" "}
                                      </a>
                                    )}

                                  <span>{cell.value}</span>
                                </TableCell>
                              );
                            }
                            break;
                          case "icon":
                            return (
                              <TableCell
                                className="cp-data-table-content-cell"
                                align={"left"}
                                key={
                                  cellIndex + Math.floor(Math.random() * 100)
                                }
                              >
                                {/* <a
                                  href={row[row.length - 1].value}
                                  title={cell.value || ""}
                                  aria-label={cell.value || ""}
                                >
                                  {" "}
                                </a> */}
                                {cell.value}
                              </TableCell>
                            );
                          case "status":
                            return (
                              <TableCell
                                className="cp-data-table-content-cell cp-data-table-content-cell-status"
                                align={"left"}
                                key={cell.value + cellIndex}
                              >
                                {row[row.length - 1].type === "link" && (
                                  <a
                                    href={row[row.length - 1].value}
                                    title={cell.value || ""}
                                    aria-label={cell.value || ""}
                                  >
                                    {" "}
                                  </a>
                                )}
                                <span
                                  className={"cp-status cp-gray-status "}
                                  style={
                                    cell.style
                                      ? {
                                          color: cell.style.textColor,
                                          backgroundColor:
                                            cell.style.backgroundColor,
                                        }
                                      : {}
                                  }
                                >
                                  <span
                                    className={"cp-status-dot"}
                                    style={
                                      cell.style
                                        ? {
                                            backgroundColor:
                                              cell.style.textColor,
                                          }
                                        : {}
                                    }
                                  ></span>
                                  {cell.text ? cell.text : cell.value}
                                </span>
                              </TableCell>
                            );

                          case "document":
                            return (
                              <TableCell
                                className="cp-data-table-content-cell"
                                align={"left"}
                                key={cell.value + cellIndex}
                              >
                                <span className="cp-data-table-document">
                                  {cell.value.length > 1 && (
                                    <CPTooltip
                                      title={translate("downloadAllFiles")}
                                    >
                                      <Link
                                        //target="_blank"
                                        rel="noopener"
                                        href={""}
                                        key={"downloadAll"}
                                        sx={{ cursor: "pointer" }}
                                        onClick={(e: any) => {
                                          downloadAll(e, cell.value, index);
                                        }}
                                      >
                                        <DownloadAllIcon
                                          className="cp-data-table-downloadall-icon"
                                          key={"downloadAll" + index}
                                        />
                                      </Link>
                                    </CPTooltip>
                                  )}
                                  {cell.value.map(
                                    (file: any, cellValueIndex: any) => {
                                      let icon: any;
                                      if (file.type === "pdf") {
                                        icon = <PdfFile key={cellValueIndex} />;
                                      } else if (file.type === "doc") {
                                        icon = <DocFile key={cellValueIndex} />;
                                      } else if (file.type === "xlsx") {
                                        icon = (
                                          <XlsxFile key={cellValueIndex} />
                                        );
                                      }
                                      let link = (
                                        <CPTooltip title={file.name || ""}>
                                          <Link
                                            //target="_blank"
                                            rel="noopener"
                                            href={""}
                                            key={cellValueIndex}
                                            sx={{ cursor: "pointer" }}
                                            onClick={(e: any) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                              if (
                                                downloading[index][
                                                  cellValueIndex
                                                ] === true
                                              )
                                                return;
                                              downloading[index][
                                                cellValueIndex
                                              ] = true;

                                              setDaownloding(downloading);
                                              setRows([...rows]);
                                              console.log("FILE Object", file);
                                              DownloadServices.getDocument({
                                                name: file.name,
                                                id: file.id || null,
                                                sampleId: file.sampleId || null,
                                                orderId: file.orderId || null,
                                                invoiceId:
                                                  file.invoiceId || null,
                                                quoteId: file.quoteId || null,
                                              })
                                                .then(() => {
                                                  // console.log("Called");
                                                  downloading[index][
                                                    cellValueIndex
                                                  ] = false;
                                                  setDaownloding(downloading);
                                                  setRows([...rows]);
                                                  setToast({
                                                    ...{
                                                      open: true,
                                                      type: "success",
                                                      message:
                                                        file.name +
                                                        " " +
                                                        translate(
                                                          "isDownloading"
                                                        ),
                                                    },
                                                  });
                                                })
                                                .catch((err: any) => {
                                                  downloading[index][
                                                    cellValueIndex
                                                  ] = false;
                                                  setDaownloding(downloading);
                                                  setRows([...rows]);
                                                  setToast({
                                                    ...{
                                                      open: true,
                                                      type: "error",
                                                      message:
                                                        translate("failed"),
                                                    },
                                                  });
                                                });
                                            }}
                                          >
                                            {downloading[index][
                                              cellValueIndex
                                            ] === true ? (
                                              <LoadingIcon className="cp-table-loading-icon" />
                                            ) : (
                                              icon
                                            )}
                                          </Link>
                                        </CPTooltip>
                                      );
                                      //   console.log("Link: ", file);
                                      return link;
                                    }
                                  )}
                                </span>
                              </TableCell>
                            );

                          default:
                            break;
                        }
                      })}
                      {/* <TableCell
                      className="cp-data-table-content-cell"
                      align={"left"}
                    >
                      {row.label}
                    </TableCell> */}
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {/* Show Proper Message when No Data Available */}
      {props.isLoading === false && rows.length <= 0 && (
        <CPInfoBox
          title={props.noDataInfo.title}
          icon={props.noDataInfo.icon}
          desc={props.noDataInfo.desc}
        />
      )}

      <CPToast
        open={toast.open}
        type={toast.type}
        message={toast.message}
        key={toast.message}
        getValue={(value: any) =>
          setToast({
            ...{
              open: false,
              type: "",
              message: "",
            },
          })
        }
      />

      {props.disablePagination !== true && (
        <TablePagination
          className="cp-table-pagination"
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={totalItems || rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={translate("rowsPerPage")}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} ${translate("of")} ${
              count !== -1 ? count : `more than ${to}`
            }`;
          }}
        />
      )}
    </div>
  );
}

export default CPTable;
